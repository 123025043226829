import React from "react";
import Card from "~/components/styled/card";
import AirtableEmbed from "~/components/AirtableEmbed";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { formatPrice } from "~/helpers/currency-formatter";
import { ArrowRightIcon } from "@heroicons/react/outline";
import PageHeading from "~/components/styled/page-heading";

const GuaranteedRides = () => {
	return (
		<Layout>
			<GatsbySeo
				title="Guaranteed Bike Tours and Cycling Holidays"
				description="See the list of our guaranteed-to-depart bike tours in India and Asia."
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: "https://www.artofbicycletrips.in/upcoming-rides",
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: "https://www.artofbicycletrips.com/private",
				// 	},
				// ]}
			/>

			<section className="mt-4 mb-10">
				<h1 className="font-bold">Guaranteed Departures</h1>
				<p className="font-light mt-2 text-primary text-xl max-w-3xl mb-14">
					Tours that have met our minimum people requirement
				</p>

				{/* Airtable */}
				<AirtableEmbed />
			</section>

			{/* <section className="mt-4 mb-10">
				<h1 className="font-bold">Guaranteed Departures</h1>
				<p className="font-light mt-2 text-primary text-xl max-w-3xl mb-14">
					Tours that have met our minimum people requirement
				</p>

				<h2 className="mt-20 mb-6">January 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/philippines-island-odyssey-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/philippines_bohol.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Philippines Island Odyssey Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Philippines
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3595 && formatPrice(3595)} | 01 Jan, 2025 to Tue, 14 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-rajasthan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/rajasthan-bike-tour-4.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Rajasthan Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1895 && formatPrice(1895)} | 04 Jan to 11 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/ho-chi-minh-trail-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-12.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Ho Chi Minh Trail by Gravel Bike
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1895 && formatPrice(1895)} | 09 Jan to Thu, 16 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-vietnam-north-to-south/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-29.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Ultimate Vietnam North to South
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4195 && formatPrice(4195)} | 10 Jan to 26 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">17 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/classic-laos-luang-prabang-to-vientiane-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/laos-bike-tour-2.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Classic Laos - Luang Prabang to Vientiane Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Laos</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1895 && formatPrice(1895)} | 12 Jan, 2025 to Sun, 19 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/thailnd-multisport-bike-hike/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-9.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Off-Beat Northern Thailand Bike, Hike & Raft
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2895 && formatPrice(2895)} | Sun, 12 Jan, 2025 to Wed, 22 Jan,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">11 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/kerala-escapade-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/kerala-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Kerala Escapade
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2195 && formatPrice(2195)} | 12 Jan to Tue, 21 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">10 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/exquisite-thailand-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Exquisite Thailand Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3195 && formatPrice(3195)} | 12 Jan to 20 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/central-goa-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/goa-bike-tour-7.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Enchanting Goa Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1195 && formatPrice(1195)} | 12 Jan, 2025 to 16 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">5 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/portrayal-of-vietnam-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-13.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Portrayal of Vietnam Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3995 && formatPrice(3995)} | 12 Jan, 2025 to Tue, 21 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">10 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/saigon-to-angkor-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize hover:text-primary">
										Saigon to Angkor Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | 12 Jan to 20 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 12 Jan to 20 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/southern-thailand-bangkok-to-phuket-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-53.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Southern Thailand: Bangkok to Phuket Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3895 && formatPrice(3895)} | 19 Jan to Sat, 01 Feb, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/northern-vietnam-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-17.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Vietnam Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2095 && formatPrice(2095)} | 19 Jan to 27 Jan, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/vivid-srilanka-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-8.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Vivid Sri Lanka Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 19 Jan to 01 Feb, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/chiang-mai-to-chiang-rai-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Chiang Mai to Chiang Rai Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1995 && formatPrice(1995)} | Sun, 26 Jan, 2025 to Sun, 02 Feb,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>

				<h2 className="mt-20 mb-6">February 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/thailand-laos-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-53.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Thailand & Laos Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand & Laos
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3695 && formatPrice(3695)} | Sun, 02 Feb, 2025 to Sat, 15 Feb,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/chiang-mai-to-chiang-rai-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Chiang Mai to Chiang Rai Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1995 && formatPrice(1995)} | 02 Feb to 09 Feb, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/portrayal-of-vietnam-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-13.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Portrayal of Vietnam Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3995 && formatPrice(3995)} | Sat, 08 Feb, 2025 to Mon, 17 Feb,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">10 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/philippines-island-odyssey-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/philippines_bohol.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Philippines Island Odyssey Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Philippines
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3595 && formatPrice(3595)} | 08 Feb to 21 Feb
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/classic-laos-luang-prabang-to-vientiane-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/laos-bike-tour-2.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Classic Laos - Luang Prabang to Vientiane Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Laos</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1895 && formatPrice(1895)} | Thu, 13 Feb, 2025 to Thu, 20 Feb,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/saigon-to-angkor-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize hover:text-primary">
										Saigon to Angkor Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | Sun, 16 Feb, 2025 to Mon, 24 Feb,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/glimpse-sri-lanka-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-8.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Glimpse of Sri Lanka Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2195 && formatPrice(2195)} | 16 Feb to Mon, 24 Feb, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/exotic-india-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/rajasthan-bike-tour-23.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Exotic India Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3595 && formatPrice(3595)} | 15 Feb, 2025 to 28 Feb, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 16 Feb, 2025 to 24 Feb, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/best-of-rajasthan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/rajasthan-bike-tour-4.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Rajasthan Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1895 && formatPrice(1895)} | 16 Feb to 23 Feb, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/kerala-in-style-bike-hike-kayak/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/kerala-bike-tour-4.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Kerala in Style: bike, hike, kayak
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">India</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2295 && formatPrice(2295)} | 09 Feb to 19 Feb, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">11 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/northern-vietnam-bike-hike-kayak/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-29.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Vietnam: bike, hike, kayak
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3195 && formatPrice(3195)} | 09 Feb to 22 Feb
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/thailand-laos-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/thailand-bike-tour-53.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Thailand & Laos Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Thailand & Laos
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3695 && formatPrice(3695)} | 23 Feb - 08 Mar, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">March 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/best-of-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | Sun, 02 Mar, 2025 to Mon, 10 Mar,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-vietnam-north-to-south/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-29.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Ultimate Vietnam North to South
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4195 && formatPrice(4195)} | 02 Mar to 18 Mar, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">17 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/classic-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Classic Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {5295 && formatPrice(5295)} | 02 Mar to 14 Mar, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">13 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/coastal-vietnam-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-12.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Coastal Vietnam Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | 09 Mar to Mon, 17 Mar, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/saigon-to-angkor-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-5.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize hover:text-primary">
										Saigon to Angkor Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Vietnam, Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | 09 Mar, 2025 to Mon, 17 Mar, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/highlights-of-cambodia-bike-tour">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/cambodia-bike-tour-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Highlights of Cambodia
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Cambodia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1515 && formatPrice(1515)} | 10 Mar, 2025 to Sun, 16 Mar, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">7 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/northern-vietnam-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-17.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Northern Vietnam Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2095 && formatPrice(2095)} | 16 Mar to 24 Mar
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/mekong-delta-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-22.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Mekong Delta Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1095 && formatPrice(1095)} | 24 Mar to 27 Mar
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">4 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/kyoto-sea-of-japan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/japan-kyoto-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Kyoto to Sea of Japan Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Japan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4495 && formatPrice(4495)} | 26 Mar to 02 Apr, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/hue-hoi-an-family-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-12.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Hue to Hoi An Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1295 && formatPrice(1295)} | 30 Mar to Fri, 04 Apr, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">6 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">April 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/philippines-island-odyssey-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/philippines_bohol.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Philippines Island Odyssey Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Philippines
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3595 && formatPrice(3595)} | 02 Apr, 2025 to Tue, 15 Apr, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/vivid-srilanka-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-8.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Vivid Sri Lanka Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 06 - 19 Apr, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">May 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/best-of-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | Thu, 01 May, 2025 to Fri, 09 May,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/self-guided-douro-valley-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/douro-portugal.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Self-guided Porto & Douro Valley Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Portugal
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {1895 && formatPrice(1895)} | Sat, 10 May, 2025 to Sat, 17 May,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/cycling-vietnam-north-to-south/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-29.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Ultimate Vietnam North to South
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3995 && formatPrice(3995)} | Mon, 12 May, 2025 to Wed, 28 May,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">17 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/essense-of-shikoku-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/shikoku-9.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Essence of Shikoku Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Japan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4195 && formatPrice(4195)} | Tue, 13 May, 2025 to Tue, 20 May,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>

					<Card>
						<Link to="/tours/montenegro-albania-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/Albania_Herceg_Novi.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Montenegro and Albania Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Montenegro, Albania
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2395 && formatPrice(2395)} | Sun, 18 May, 2025 to Sun, 25 May,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">June 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/essense-of-shikoku-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/shikoku-9.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Essence of Shikoku Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Japan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4195 && formatPrice(4195)} | 03 Jun to 10 Jun
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/self-guided-split-to-dubrovnik-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/split-1.webp"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Self-guided Split to Dubrovnik Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Croatia</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2395 && formatPrice(2395)} | 14 Jun to 21 Jun
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/kyoto-sea-of-japan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/japan-kyoto-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Kyoto to Sea of Japan Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Japan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4495 && formatPrice(4495)} | 27 Jun to 04 Jul, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">July 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/fjords-of-norway-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/norwaylake.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Fjords of Norway Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Norway</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4695 && formatPrice(4695)} | 10 Jul to 17 Jul, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/coastal-vietnam-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/vietnam-bike-tour-12.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Coastal Vietnam Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Vietnam</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3495 && formatPrice(3495)} | 13 Jul to 21 Jul
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/mongolia-steppe-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/Mangolia_Orkhon.webp"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Mongolia Steppe Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Mongolia
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3995 && formatPrice(3995)} | 13 Jul to 26 Jul
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>

				<h2 className="mt-20 mb-6">August 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/fjords-of-norway-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/norwaylake.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Fjords of Norway Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Norway</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4695 && formatPrice(4695)} | Mon, 18 Aug, 2025 to Mon, 25 Aug,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/self-guided-porto-santiago-compostela-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/camino.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Self-guided Porto to Santiago de Compostela Coastal Path Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Portugal
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2095 && formatPrice(2095)} | 24 Aug to 31 Aug
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">
											casual plus inns
										</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">September 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/alentejo-castles-beaches-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/portugal-evora.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Alentejo to Algarve Castles and Beaches Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Portugal
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3695 && formatPrice(3695)} | 06 Sep to 13 Sep, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/kyoto-sea-of-japan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/japan-kyoto-1.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Kyoto to Sea of Japan Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Japan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4595 && formatPrice(4595)} | 11 Sep to 18 Sep, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/classic-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Classic Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {5295 && formatPrice(5295)} | 13 - 25 Sep, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">13 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/albania-unesco-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/albania-tour.jpg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Albania Unesco Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Albania</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2395 && formatPrice(2395)} | Sat, 13 Sep, 2025 to Mon, 22 Sep,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">10 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/japan-hokkaido-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/east-hokkaido-cycling-east-coast.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Eastern Hokkaido Lakes & Coast Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Japan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {4795 && formatPrice(4795)} | 14 Sep to 21 Sep
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/glimpse-sri-lanka-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-8.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Glimpse of Sri Lanka Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {2295 && formatPrice(2295)} | 14 - 22 Sep, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/alentejo-castles-beaches-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/portugal-evora.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Alentejo to Algarve Castles and Beaches Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Portugal
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3695 && formatPrice(3695)} | 20 Sep, 2025 to 27 Sep, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/mystical-bhutan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/bhutan-bike-tour-3.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Bhutanese Dragon Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Bhutan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {6195 && formatPrice(6195)} | Sun, 28 Sep, 2025 to Sat, 11 Oct,
										2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">October 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/alentejo-castles-beaches-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/portugal-evora.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Alentejo to Algarve Castles and Beaches Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Portugal
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3995 && formatPrice(3995)} | 11 - 18 Oct, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">8 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
					<Card>
						<Link to="/tours/mystical-bhutan-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/bhutan-bike-tour-3.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black uppercase font-medium">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Bhutanese Dragon Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">Bhutan</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {6195 && formatPrice(6195)} | 12 Oct to 25 Oct
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">14 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">casual inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
				<h2 className="mt-20 mb-6">November 2025</h2>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-20">
					<Card>
						<Link to="/tours/best-of-sri-lanka-luxury-bike-tour/">
							<div className="relative">
								<StaticImage
									className="rounded-t-lg shadow-lg transform"
									src="../images/srilanka-bike-tour-10.jpeg"
									alt=""
									layout="FULL_WIDTH"
									aspectRatio="1.3"
								/>
								<div className="absolute  top-3 left-3 bg-inclusions  rounded-full px-3 py-1 ">
									<p className="text-xs text-black font-medium uppercase">
										Guaranteed-to-depart
									</p>
								</div>
							</div>
							<div className="px-4 py-2 ">
								<div className="mb-0.5">
									<p className="font-medium capitalize  hover:text-primary">
										Best of Sri Lanka Luxury Bike Tour
									</p>
								</div>
								<div className="flex mb-4">
									<div>
										<p className="text-xs border rounded-full p-0.5 px-2 mr-1">
											Sri Lanka
										</p>
									</div>

									<p className="text-xs border rounded-full p-0.5 px-2">cycling</p>
								</div>
								<div>
									<p className="text-sm font-semibold self-end">
										From {3295 && formatPrice(3295)} | 16 - 24 Nov, 2025
									</p>
								</div>
								<div className="flex items-baseline">
									<div className="text-sm text-gray-500 lowercase">9 days</div>
									<div>
										<span className="mx-1 text-sm text-gray-500"> | </span>
									</div>
									<div>
										<span className="text-sm text-gray-500 lowercase">premiere inns</span>
									</div>
								</div>
							</div>
						</Link>
					</Card>
				</div>
			</section> */}
		</Layout>
	);
};

export default GuaranteedRides;
