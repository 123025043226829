import React, { useState, useEffect } from "react";

const AirtableEmbed = () => {
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		setLoaded(true);
	}, []);

	return (
		<div>
			{loaded ? (
				<iframe
					className="airtable-embed"
					src="https://airtable.com/embed/appGwDax1mXNsKh6S/shreJGY4MSxfBB9CZ"
					frameBorder="0"
					width="100%"
					height="533"
					style={{ background: "transparent", border: "1px solid #ccc" }}
				></iframe>
			) : (
				<p>Loading...</p>
			)}
		</div>
	);
};

export default AirtableEmbed;
